// external imports
import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'

// helpers
import { up, addResponsivity } from '../lib/styles'
import { extractNodes, extractNodesFromData } from '../lib/helpers'
import injectIntl from '../lib/intl/injectIntl'
import withExtraction from '../lib/withExtraction'

// components
import SEO from '../components/SEO'
import {
  Page,
  PageContent,
  Title,
  Gap,
  Row,
  Col,
  Link,
  Text,
  RH,
  Button,
  HeaderBar,
  Card,
  Testimonials,
  Line,
  SubHeaderBar,
  MethodPageLayout,
} from '../components'
import headerBarImageSrc from '../data/images/methods-top.png'
import T from '../lib/intl/T'

const MethodImageWrapper = props =>
  <Col maxWidth="100%" width="517px" shrink={1} {...props} />

const MethodImageGap = () => (
  <>
    <Gap gap="62px" hideBelow="desktop" />
    <Gap gap="80px" mobileGap="48px" />
  </>
)

const MethodGap = props => <Gap gap="124px" mobileGap="60px" {...props} />

const MethodDivider = () => (
  <>
    <MethodGap mobileGap="54px" />
    <Line />
    <MethodGap />
  </>
)

const Method = ({
  isReversed,
  image,
  upHeader,
  header,
  link,
  children,
  contentMaxWidth = '600px',
  ...props
}) => (
  <Row
    width="100%"
    alignItems="center"
    responsive
    mobileFlexDirection={isReversed ? 'column-reverse' : 'column'}
    {...props}
  >
    {!isReversed && (
      <>
        <MethodImageWrapper>
          <Img fluid={image.image.fluid} />
        </MethodImageWrapper>
        <MethodImageGap />
      </>
    )}
    <Col shrink={2} maxWidth={contentMaxWidth} mobileMaxWidth="517px">
      <Title.MiniOrange>{upHeader}</Title.MiniOrange>
      <Gap gap="24px" mobileGap="20px" />
      <Link.Simple {...link}>
        <Title.Section>{header}</Title.Section>
      </Link.Simple>
      <Gap gap="26px" mobileGap="20px" />
      <Text.Wide>{children}</Text.Wide>
      <Gap gap="24px" />
      <Link.OrangeLink {...link}>» ČÍST DÁL</Link.OrangeLink>
    </Col>
    {isReversed && (
      <>
        <MethodImageGap />
        <MethodImageWrapper>
          <Img fluid={image.image.fluid} />
        </MethodImageWrapper>
      </>
    )}
  </Row>
)

class Methods extends React.Component {
  componentDidMount() {}

  render() {
    const {
      location: { pathname },
      intl: { language, t },
      data: {
        imageMethod,
      },
    } = this.props
    const images = [imageMethod]

    return (
      <>
        <SEO title={t('O metodách')} pathname={pathname} />
        <HeaderBar>
          <HeaderBar.Content shrink={1}>
            <HeaderBar.Title>
              <T>O metodách</T>
            </HeaderBar.Title>
            <HeaderBar.SubTitle>
              <T>Podrobné informace o metodách, autorech, inspiraci</T>
            </HeaderBar.SubTitle>
          </HeaderBar.Content>
          {/* <HeaderBar.ImageWrapper
            padding="0 80px 0 20px"
            mobileMaxWidth="350px"
          >
            <img src={headerBarImageSrc} alt="" />
          </HeaderBar.ImageWrapper> */}
        </HeaderBar>

        <SubHeaderBar
          contentMaxWidth="1200px"
          style={{ minHeight: '192px' }}
          white={language === 'en'}
        >
          <SubHeaderBar.Text maxWidth={language === 'en' ? '700px' : undefined}>
            <T raw>O metodach - sedy text</T>
          </SubHeaderBar.Text>
        </SubHeaderBar>

        {language === 'cs' && (
          <>
            <Page>
              <PageContent>
                <Gap gap="88px" mobileGap="60px" />

                <MethodPageLayout.Content
                  images={images}
                  mobileMaxWidth="500px"
                >
                  <Text.Wide>
                    <T raw>miu - content</T>
                  </Text.Wide>
                </MethodPageLayout.Content>
                <Gap gap="88px" mobileGap="60px" />
              </PageContent>
            </Page>

            <Gap gap="192px" mobileGap="62px" />

            <Page>
              <PageContent>
                <Testimonials />
              </PageContent>
            </Page>
            <Gap gap="198px" mobileGap="97px" />
          </>
        )}
      </>
    )
  }
}

export default injectIntl(withExtraction(Methods))

export const pageQuery = graphql`
  query {
    imageMethod: file(relativePath: { eq: "images/methods/methods5.png" }) {
      image: childImageSharp {
        fluid(maxWidth: 452, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
